import _objectSpread from "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapGetters } from "vuex";
export default {
  name: "searchComponent",
  data: function data() {
    return {
      value: "",
      show: false
    };
  },
  computed: _objectSpread({}, mapGetters("router", ["routerList"])),
  methods: {
    changeRouter: function changeRouter() {
      this.$router.push({
        name: this.value
      });
      this.value = "";
    },
    hiddenSearch: function hiddenSearch() {
      this.show = false;
    },
    showSearch: function showSearch() {
      var _this = this;
      this.show = true;
      this.$nextTick(function () {
        _this.$refs["search-input"].focus();
      });
    }
  }
};